// @import "~bootstrap/scss/bootstrap";

body {
	margin: 0;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #3C4858;
	text-align: left;
	background-color: #da251c;
}