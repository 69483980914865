div.scrollimages {
  overflow: auto;
  white-space: nowrap;
}
div.scrollimages a {
  background-color: #333;
  display: inline-block;
  color: white;
  text-align: center;
  padding: 5px;
  margin: 10px;
  text-decoration: none;
}
div.scrollimages a:hover {
  background-color: #777;
}
div.scrollimages a img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}